/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    // Global
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Global/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    // Widgets
    {
        name: 'AnnouncementBar',
        import: () => import('@stories/Widgets/Global/AnnouncementBar/AnnouncementBar'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ArticleBanner',
        import: () => import('@stories/Widgets/ArticleBanner/ArticleBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'AwardsBanner',
        import: () => import('@stories/Widgets/AwardsBanner/AwardsBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BookADemoForm',
        import: () => import('@stories/Widgets/BookADemoForm/BookADemoForm'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'BlogCarousel',
        import: () => import('@stories/Widgets/BlogCarousel/BlogCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlogListing',
        import: () => import('@stories/Widgets/BlogListing/BlogListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyCarousel',
        import: () => import('@stories/Widgets/CaseStudyCarousel/CaseStudyCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyListing',
        import: () => import('@stories/Widgets/CaseStudyListing/CaseStudyListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MarketplaceList',
        import: () => import('@stories/Widgets/MarketplaceList/MarketplaceList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryListCards',
        import: () => import('@stories/Widgets/CategoryListCards/CategoryListCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUsForm',
        import: () => import('@stories/Widgets/ContactUsForm/ContactUsForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ContentDownloadList',
        import: () => import('@stories/Widgets/ContentDownloadList/ContentDownloadList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Faqs',
        import: () => import('@stories/Widgets/Faqs/Faqs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureGrid',
        import: () => import('@stories/Widgets/FeatureGrid/FeatureGrid'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'FeaturedBanner',
        import: () => import('@stories/Widgets/FeaturedBanner/FeaturedBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Features',
        import: () => import('@stories/Widgets/Features/Features'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FullWidthSignpost',
        import: () => import('@stories/Widgets/FullWidthSignpost/FullWidthSignpost'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GenericListing',
        import: () => import('@stories/Widgets/GenericListing/GenericListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HistoryTimeline',
        import: () => import('@stories/Widgets/HistoryTimeline/HistoryTimeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomepageHeroBanner',
        import: () => import('@stories/Widgets/HomepageHeroBanner/HomepageHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Image',
        import: () => import('@stories/Widgets/Image/Image'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageText5050',
        import: () => import('@stories/Widgets/ImageText5050/ImageText5050'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageBanner',
        import: () => import('@stories/Widgets/InnerPageBanner/InnerPageBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'InPageForm',
        import: () => import('@stories/Widgets/InPageForm/InPageForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoList',
        import: () => import('@stories/Widgets/LogoList/LogoList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'JoinMarketplaceForm',
        import: () => import('@stories/Widgets/JoinMarketplaceForm/JoinMarketplaceForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'MeetTheTeam',
        import: () => import('@stories/Widgets/MeetTheTeam/MeetTheTeam'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NextSteps',
        import: () => import('@stories/Widgets/NextSteps/NextSteps'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PremiumResourceBanner',
        import: () => import('@stories/Widgets/PremiumResourceBanner/PremiumResourceBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Pricing',
        import: () => import('@stories/Widgets/Pricing/Pricing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Quote',
        import: () => import('@stories/Widgets/Quote/Quote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'Signposts',
        import: () => import('@stories/Widgets/Signposts/Signposts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatsList',
        import: () => import('@stories/Widgets/StatsList/StatsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarousel',
        import: () => import('@stories/Widgets/TestimonialCarousel/TestimonialCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TopLevelFeatures',
        import: () => import('@stories/Widgets/TopLevelFeatures/TopLevelFeatures'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'USPList',
        import: () => import('@stories/Widgets/USPList/USPList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSiteMap',
        import: () => import('@stories/Widgets/HtmlSiteMap/HtmlSiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    // Forms
    {
        name: 'KenticoForm',
        import: () => import('@stories/Components/Misc/Forms/KenticoForm/KenticoForm'),
        config: {
            hydrate: 'never',
        },
    },
];
